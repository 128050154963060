<template>
    <section>
        <v-tabs show-arrows v-model="tab" slider-color="primary" role="tablist" background-color="transparent">
            <v-tab v-for="tab in tabs" :key="tab.id" class="poppins text-wrap rounded" active-class="custom-border white primary--text" >
                <v-badge
                    v-if="tab.count > 0"
                    overlap
                    inline
                    bordered
                    :color="tab.count === 0 ? 'transparent' : 'primary'"
                    :content="tab.count"
                    :offset-x="15"
                    style="text">
                    {{  tab.text }}
                </v-badge>
                <span v-else>
                    {{  tab.text }}
                </span>
            </v-tab>
        </v-tabs>
        
        <section class="text-right">
            <FormLabel :label="`${totalCount} result/s`"/>
        </section>
        <v-card>
            <v-data-table
                :headers="admin_ticket_tbl"
                :items="tickets"
                :loading="loading"
                class="poppins"
                :footer-props="{
                    'items-per-page-options': itemsPerPageOptions
                }"
                :page="page"
                :server-items-length="totalCount"
                @pagination="(e) => {
                    page = e.page
                    paginate = String(e.itemsPerPage),
                    getTickets()
                }">
                <template v-slot:item.id="{ item }">
                    <v-btn text class="fw400" color="primary" @click="openDialog(item)">
                        EDU-{{item.id}}
                    </v-btn>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{$dateFormat.dayDateTime(item.created_at)}}
                </template>
            </v-data-table>
            <!-- <FormPagination 
                :pageCount="pageCount" 
                :page="page"
                :paginate="paginate"
                @page="(e) => { page = e, getTickets() }" 
                @paginate="(e) => { paginate = e, page = 1, getTickets() }"/> -->
        </v-card>
        
        <TicketDialog v-if="selectedTicket" :dialog="dialog" :ticket="selectedTicket" @close="closeDialog"/>
        
    </section>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { admin_ticket_tbl } from '@/constants/tblheaders/ticket'
import TicketDialog from '@/components/admin/ticket/TicketDialog.vue';
import { mapState, mapActions } from 'vuex';

export default defineComponent({
    components: {
        TicketDialog
    },
    data: () => ({
        tab: 0,
        admin_ticket_tbl,
        loading: true,
        dialog: false,
        selectedTicket: null,
        itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
        paginate: '10',
        page: 1,
        pageCount: 1,
        totalCount: 0,
        tabs: [ 
            {
                text: "Platform",
                id: "platform",
                count: 0
            },
            {
                text: "Content",
                id: "content",
                count: 0
            },
            {
                text: "Assessment",
                id: "assessment",
                count: 0
            },
            {
                text: "Others",
                id: "others",
                count: 0
            }
        ],
    }),
    methods: {
        ...mapActions('admin', ['getTicketsAction', 'getTicketCountAction']),
        openDialog(item){
            this.dialog = true
            this.selectedTicket = item
        },

        closeDialog(){
            this.dialog=false
        },

        getTickets(){
            this.loading = true
            this.getTicketsAction({ category: this.tabs[this.tab].id, paginate: Number(this.paginate), page: this.page }).then(res => {
                this.loading = false
                this.pageCount = res.last_page
                this.totalCount = res.total
                this.page = res.current_page
            }).catch(() => this.loading = false)
        },

        getTicketCount(){
            this.getTicketCountAction().then(res => {
                this.tabs[0].count = res.total_platform_tickets
                this.tabs[1].count = res.total_content_tickets
                this.tabs[2].count = res.total_assessment_tickets
                this.tabs[3].count = res.total_others_tickets
                this.getTickets() 
            })
        }
    },
    computed: {
        ...mapState('admin', {
            tickets: (state) => state.tickets
        }),

        itemsPerPage(){
            return Number(this.paginate)
        }
    },
    mounted(){
        if(Object.values(this.$route.query).length == 0){
            this.$router.replace({ query: { page: 1, paginate: Number(this.paginate), category: this.tab, timestamp: Date.now(),  } });
        } else {
            let { page, paginate, category } = this.$route.query

            if (page) {
                this.page = Number(page)
            } 
            if (paginate) {
                this.paginate = paginate
            }
            if (category) {
                this.tab = category
            }
        }
        this.getTicketCountAction().then(res => {
            this.tabs[0].count = res.total_platform_tickets
            this.tabs[1].count = res.total_content_tickets
            this.tabs[2].count = res.total_assessment_tickets
            this.tabs[3].count = res.total_others_tickets
            this.getTickets() 
        }).catch(() => {
            this.loading = true
        })
        
    },
    watch:{
        tab(val){
            this.$router.replace({ query: { ...this.$route.query, tab: val, timestamp: Date.now() } });
            this.getTickets()
        },

        paginate(val) {
            this.$router.replace({ query: { ...this.$route.query, paginate: val, timestamp: Date.now() } });
        },

        page(val) {
            this.$router.replace({ query: { ...this.$route.query, page: val, timestamp: Date.now() } });
        }
    }
})
</script>
