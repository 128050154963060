var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('v-tabs',{attrs:{"show-arrows":"","slider-color":"primary","role":"tablist","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,staticClass:"poppins text-wrap rounded",attrs:{"active-class":"custom-border white primary--text"}},[(tab.count > 0)?_c('v-badge',{staticStyle:{},attrs:{"overlap":"","inline":"","bordered":"","color":tab.count === 0 ? 'transparent' : 'primary',"content":tab.count,"offset-x":15}},[_vm._v(" "+_vm._s(tab.text)+" ")]):_c('span',[_vm._v(" "+_vm._s(tab.text)+" ")])],1)}),1),_c('section',{staticClass:"text-right"},[_c('FormLabel',{attrs:{"label":`${_vm.totalCount} result/s`}})],1),_c('v-card',[_c('v-data-table',{staticClass:"poppins",attrs:{"headers":_vm.admin_ticket_tbl,"items":_vm.tickets,"loading":_vm.loading,"footer-props":{
                'items-per-page-options': _vm.itemsPerPageOptions
            },"page":_vm.page,"server-items-length":_vm.totalCount},on:{"pagination":(e) => {
                _vm.page = e.page
                _vm.paginate = String(e.itemsPerPage),
                _vm.getTickets()
            }},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('v-btn',{staticClass:"fw400",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openDialog(item)}}},[_vm._v(" EDU-"+_vm._s(item.id)+" ")])]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$dateFormat.dayDateTime(item.created_at))+" ")]}}])})],1),(_vm.selectedTicket)?_c('TicketDialog',{attrs:{"dialog":_vm.dialog,"ticket":_vm.selectedTicket},on:{"close":_vm.closeDialog}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }